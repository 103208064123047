<template>
    <v-card v-if="!isHidden" class="note" :class="{'d-flex flex-column editing': editing}" :tile="editing" :color="checked && !editing ? 'green darken-4' : (!editing ? 'secondary' : '')" @click="edit" :ripple="!editing">        
        <v-card-actions :class="{'secondary': editing}">
            <v-textarea v-if="editing" auto-grow rows="1" label="Text" placeholder="empty" hide-details="auto" v-model="text" />
            <pre class="note-text" v-else>{{ text ? text : "empty" }}</pre>
        </v-card-actions>
        <v-sheet v-if="editing" color="transparent">
            <v-card-actions>
                <v-combobox
                    v-model="label"
                    :items="availableLabels"
                    :delimiters="delimiters"
                    placeholder="Something to describe the note"
                    label="Label"
                    chips
                    hide-details="auto"
                    color="green"
                />
            </v-card-actions>
            <v-card-actions>
                <v-combobox
                    v-model="main_location"
                    :items="availableMainLocations"
                    :delimiters="delimiters"
                    placeholder="Name of the area"
                    label="Location"
                    chips
                    hide-details="auto"
                    color="red"
                />
            </v-card-actions>
            <v-card-actions>
                <v-combobox
                    v-model="sub_location"
                    :items="availableSubLocations"
                    :delimiters="delimiters"
                    placeholder="Something to describe the area"
                    label="Sub Location"
                    chips
                    hide-details="auto"
                    color="red"
                />
            </v-card-actions>
            <v-card-actions>
                <v-combobox
                    v-if="editing"
                    v-model="tags"
                    :items="availableTags"
                    :delimiters="delimiters"
                    placeholder="Some extra tags"
                    label="Tags"
                    multiple
                    chips
                    deletable-chips
                    hide-details="auto"
                />
            </v-card-actions>
        </v-sheet>

        <div class="flex-grow-1 flex-shrink-0"></div>

        <v-card-actions v-if="editing" class="secondary darken-1 mt-1">
            <v-checkbox
                label="Checked"
                class="ma-0 mx-auto pa-0"
                v-model="checked"
                hide-details="auto"
            />
            <v-spacer />
            <v-btn text color="red" class="ml-2" @click="del" @click.native.stop>Delete</v-btn>
            <v-btn text color="primary" class="ml-2" @click="close" @click.native.stop>Close</v-btn>
        </v-card-actions>

        <div class="note-tags" v-else-if="tags.length > 0 || label || sub_location">
            <v-chip
                v-if="label"
                color="green"
                class="px-2 mr-2 mb-2"
                label
                small
            >{{ label }}</v-chip>
            <v-chip
                v-if="sub_location"
                color="red"
                class="px-2 mr-2 mb-2"
                label
                small
            >{{ sub_location }}</v-chip>
            <v-chip
                v-for="(tag, i) in tags"
                :key="'note_tag_' + i"
                color="primary darken-2"
                class="px-2 mr-2 mb-2"
                label
                small
            >{{ tag }}</v-chip>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'GameNote',
    components: {},
    data: () => ({
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            number: v => ! isNaN( Number(v) ) || "Must be a number",
            emailMatch: () => (`The email and password you entered don't match`),
        },
        delimiters:[','],
        tagsvalue: []
    }),

    props: {
        editing:  {type: Boolean, default: false},
        value: {type: [Object], default: null},
        availableLabels: {type: [Object, Array], default: ()=>[]},
        availableTags: {type: [Object, Array], default: ()=>[]},
        availableMainLocations: {type: [Object, Array], default: ()=>[]},
        availableSubLocations: {type: [Object, Array], default: ()=>[]},
        isHidden: {type: [Boolean, Number], default: false},
    },
    computed: {
        shouldFocus() {
            return this.editing && ! this.title;
        },
        text: {
            get() {
                return this.value.text;
            },
            set(val) {
                this.change('text', val);
                this.$emit('change:text', val)
            }
        },
        label: {
            get() {
                return this.value.label;
            },
            set(val) {
                this.change('label', val);
                this.$emit('change:label', val)
            }
        },
        main_location: {
            get() {
                return this.value.main_location;
            },
            set(val) {
                this.change('main_location', val);
                this.$emit('change:main_location', val)
            }
        },
        sub_location: {
            get() {
                return this.value.sub_location ? this.value.sub_location : this.value.location;
            },
            set(val) {
                this.change('sub_location', val);
                this.$emit('change:sub_location', val)
            }
        },
        tags: {
            get() {
                let rawTags = this.value.tags;
                if ( typeof rawTags == "string" && rawTags.length > 0 ) {
                    return rawTags.split(',');
                }
                return [];
            },
            set(val) {
                let stringval = "";
                if ( typeof val == "string" ) {
                    stringval = val;
                } else if ( val !== null && typeof val == "object" ) {
                    let tags = [];
                    for (let tag of val) {
                        tags.push((tag+"").trim());
                    }
                    stringval = tags.join(',');
                }
                this.change('tags', stringval);
                this.$emit('change:text', stringval)
            }
        },
        checked: {
            get() {
                return !!this.value.checked;
            },
            set(val) {
                this.change('checked', !!val);
                this.$emit('change:checked', !!val)
            }
        },
    },
    mounted () {},
    methods: {
        edit() {
            if (!this.editing) {
                this.$emit('edit', this.value);
            }
        },
        change(field, value) {
            let note = {};
            for (let p in this.value) {
                if ( this.value.hasOwnProperty(p) ) {
                    note[p] = this.value[p];
                }
            }
            note[field] = value;
            this.$emit('input', note); // allows use of v-model
        },
        close() {
            this.$emit('close', this.value)
        },
        del() {
            this.$emit('delete', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.note {
    &.editing {
        min-height: calc(100vh - 56px);
    }
    .note-tags {
        position: relative;
        transform: translateY(16px);
        margin-top: -16px;
        margin-left: 8px;
        margin-right: 8px;
        pointer-events:none;
    }
    .note-text {
        white-space: break-spaces;
        font: inherit;
    }
}
.note.v-card--link:before {
  background: none;
}
</style>