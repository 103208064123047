<template>
    <v-card class="note" color="secondary" @click="edit" :ripple="!editing">
        <v-card-actions class="primary">
            <v-text-field v-if="editing" :autofocus="shouldFocus" placeholder="new note" label="Title" hide-details="auto" v-model="title" color="white" />
            <span v-else><v-icon v-if="false" class="mr-3">{{ icon }}</v-icon>{{ title ? title : "new note" }}</span>
        </v-card-actions>
        
        <v-card-actions v-if="type === 'text'">
            <v-textarea v-if="editing" auto-grow rows="1" label="Data" placeholder="empty" hide-details="auto" v-model="data" />
            <span v-else>{{ data ? data : "empty" }}</span>
        </v-card-actions>

        <template v-else-if="type === 'password'">
            <v-card-actions v-for="(row, i) in data" :key="'note_row_' + i" :class="{'pt-0': !editing && i>0 }">
                <template v-if="editing">
                    <v-text-field dense class="mr-2" placeholder="Name" hide-details="auto" v-model="row.name" />
                    <v-text-field dense placeholder="Value" hide-details="auto" v-model="row.value" />
                    <v-btn icon color="red" @click="deleteRow(i)"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <span v-else><strong>{{ row.name }}:</strong> {{ row.value }}</span>
            </v-card-actions>
            <v-card-actions v-if="editing">
                <v-spacer/>
                <v-btn text color="primary" @click="addRow">add row</v-btn>
                <v-spacer/>
            </v-card-actions>
        </template>

        <v-card-actions class="secondary darken-1 mt-1" v-if="editing">
            <v-select label="Type" hide-details="auto" v-model="type" :items="typeOptions" />
            <v-btn text color="red" class="ml-2" @click="del" @click.native.stop>Delete</v-btn>
            <v-btn text color="primary" class="ml-2" @click="close" @click.native.stop>Close</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'Note',
    components: {},
    data: () => ({
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
            number: v => ! isNaN( Number(v) ) || "Must be a number",
            emailMatch: () => (`The email and password you entered don't match`),
        },
    }),

    props: {
        editing:  {type: Boolean, default: false},
        value: {type: [Object], default: null},
        typeOptions: {type: [Array], default: () => { return [] }},
    },
    computed: {
        shouldFocus() {
            return this.editing && ! this.title;
        },
        title: {
            get() {
                return this.value.title;
            },
            set(val) {
                this.change('title', val);
                this.$emit('change:title', val)
            }
        },
        data: {
            get() {
                if ( this.type !== 'text' ) {
                    return this.value.data ? this.value.data : [];
                }
                return this.value.data;
            },
            set(val) {
                this.change('data', val);
                this.$emit('change:data', val)
            }
        },
        type: {
            get() {
                return this.value.type;
            },
            set(val) {
                this.change('type', val);
                this.$emit('change:type', val)
            }
        },
        icon() {
            switch(this.type) {
                case "text": return "mdi-text-box-outline";
                case "password": return "mdi-key-variant";
                case "website": return "mdi-web";
                default: return "mdi-alert-circle-outline";
            }
        }
    },
    mounted () {},
    methods: {
        edit() {
            if (!this.editing) {
                this.$emit('edit', this.value);
            }
        },
        addRow(){
            let rows = this.data;
            if ( typeof rows !== "object" ) {
                rows = [];
            }
            rows.push({
                type: "text",
                name: "",
                value: ""
            });
            this.data = rows;
        },
        deleteRow(index) {
            let rows = this.data;
            rows.splice(index, 1);
            this.data = rows;
        },
        change(field, value) {
            let note = {};
            for (let p in this.value) {
                if ( this.value.hasOwnProperty(p) ) {
                    note[p] = this.value[p];
                }
            }
            note[field] = value;
            this.$emit('input', note); // allows use of v-model
        },
        close() {
            this.$emit('close', this.value)
        },
        del() {
            this.$emit('delete', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.note.v-card--link:before {
  background: none;
}
</style>