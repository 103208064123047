const md5 = require('md5');
const axios = require('axios').default;

module.exports = {
  endpoint: "http://192.168.1.171/",
  password: "159753",
  utf: 8,
  charset_2:   '01',
  charset_10:  '0123456789',
  charset_32:  'abcdefghijklmnopqrstuvwxyz+-_*.?',
  charset_64:  'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVwXYZ0123456789+-',
  charset_128: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVwXYZ0123456789+-.,:;^?!|_*#$€£¤%&<>{}[]()=♂♀♪♫☼►◄▲▼↕‼¶§▬↨☻↓→←∟↔♥♦♣♠•◘○◙™®åäöÅÄÖØ',
  noises: [
    '01101001111001011011000011001111',
    '11011011010011110011100010010010',
    '01001001011010110110001110001000',
    '11100011100011010100001110000010'
  ],
  instructions: [
    "reverse",
    "reverse_fist_half",
    "reverse_last_half",
    "flip",
    "flip_even",
    "flip_odd",
    "flip_3rd",
    "noise", // apply noise[x], where x is instruction number mod noise numbers

    "mirror_2", // grab 2 bytes, eg. 10 turn them to 01
    "mirror_3", // grab 3 bytes, eg. 101 or 110 turn them to 101 or 011
    "mirror_4", // grab 4 bytesm eg. 1100 or 0101 turn them to 0011 or 1010
    "mirror_5", // etc
    "mirror_6",
    "mirror_7",
    "mirror_8",
  ],
  instructionMap: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,7,7,6],
  test() {
    return "working!";
  },
  passwordToInstructions(str) { // converts your password into an array of instruction codes
    let md = md5(str);
    let chunks = md.match(/.{1,2}/g);
    let instructions = [];
    for( let chunk of chunks ) {
      let code =   this.instructionMap[ parseInt(chunk, 16) % this.instructionMap.length ];
      instructions.push(this.instructions[code]);
    }
    return instructions;
  },
  toBinary(str) {
    let bin = "";
    for ( let i = 0; i < str.length; i++) {
        bin += ( "0".repeat(this.utf) + str[i].charCodeAt(0).toString(2) ).slice( -this.utf );
    }
    return bin;
  },
  fromBinary(bin) {
    let str = "";
    let reg = new RegExp(".{" + this.utf + "}", "g");
    let chunks = bin.match(reg);
    for ( let chunk of chunks ) {
      str += String.fromCharCode(parseInt(chunk, 2));
    }
    return str;
  },
  applyInstructions(content, instructions, reverse) {
    let index = reverse ? instructions.length - 1 : 0;

    if ( reverse ) {
      instructions = instructions.reverse();
    }

    for ( let instruction of instructions ) {
      switch(instruction) {
        case "reverse": content = this.inReverse(content); break;
        case "reverse_fist_half": content = this.inReverseH(content, true); break;
        case "reverse_last_half": content = this.inReverseH(content, false); break;
        case "flip": content = this.inFlip(content, 1 ); break;
        case "flip_even": content = this.inFlip(content, 2 ); break;
        case "flip_odd": content = this.inFlip(content, 2, 1 ); break;
        case "flip_3rd": content = this.inFlip(content, 3 ); break;
        case "noise": content = this.inNoise(content, index, reverse ); break;
        case "mirror_2": content = this.inMirror(content, 2 ); break;
        case "mirror_3": content = this.inMirror(content, 3 ); break;
        case "mirror_4": content = this.inMirror(content, 4 ); break;
        case "mirror_5": content = this.inMirror(content, 5 ); break;
        case "mirror_6": content = this.inMirror(content, 6 ); break;
        case "mirror_7": content = this.inMirror(content, 7 ); break;
        case "mirror_8": content = this.inMirror(content, 8 ); break;
        
        default: console.log('instruction "' + instruction + '" not implemented'); break;
      }
      index = index + ( reverse ? -1 : 1 );
    }
    return content;
  },
  encrypt(content, password) {
    let instructions = this.passwordToInstructions("" + password + content.length);
    //console.log(instructions);

    // convert to binary and run instructions on, then convert to b64
    let bin = this.toBinary(content);
    let enbin = this.applyInstructions(bin, instructions)
    let blob = this.fromBinary(enbin); 
    
    return blob; // todo
  },
  unencrypt(blob, password) {
    let instructions = this.passwordToInstructions("" + password + blob.length);
    // backwards of encrypt
    let enbin = this.toBinary(blob); // todo
    let bin = this.applyInstructions(enbin, instructions, true)
    let content = this.fromBinary(bin);
    return content; // todo
  },
  store(content) {
    return new Promise( async (resolve, reject) => {
      try {
        let blob = this.encrypt( content, this.password );
        let response = await axios.put(this.endpoint + "/put/new", { data: blob }, {
          crossDomain: true
        });
        resolve(response);
      } catch(error) {
        reject(error);
      }
    });
  },
  update(id, content) {
    return new Promise( async (resolve, reject) => {
      try {
        let blob = this.encrypt( content, this.password );
        let response = await axios.put(this.endpoint + "/put/" + id, { data: blob }, {
          crossDomain: true
        });
        resolve(response);
      } catch(error) {
        reject(error);
      }
    });
  },
  get(id) {
    return new Promise( async (resolve, reject) => {
      try {
        let response = await axios.get( this.endpoint + "/get/" + id );
        let data = response.data;

        if ( data.hasOwnProperty('content') ) {
          data.content = this.unencrypt( data.content, this.password );
        }
        
        resolve(data, response);
      } catch(error) {
        reject(error);
      }
    });
  },
  sleep(delay_ms) { 
    return new Promise( ( resolve ) => {
      setTimeout( () => resolve(true), delay_ms );
    });
  },
  async uploadFile(id, file) {
    // if id is null, create new 
    // upload parts at a time, request returns the id of the file, if new file, then until the first request has been sent, id is null, then set to the returned id
  },

  // instructions
  inReverse(content) {
    return content.split("").reverse().join("");
  },
  inReverseH(content, dofirst) {
    let length = content.length;
    let first = content.substr(0, length/2);
    let second = content.substr(length/2);
    if ( dofirst ) {
      first = first.split("").reverse().join("");
    } else {
      second = second.split("").reverse().join("");
    }
    return first + second;
  },
  inFlip(content, nth, offset) {
    let bits = content.split("");
    for ( let i=0; i < bits.length; i++ ) {
      let c = i + ( offset ? offset : 0 );
      let value =  bits[i];

      if ( nth == 1 || c % nth ) {
        if ( value == "0" ) {
          value = "1";
        } else {
          value = "0";
        }
      }

      bits[i] = value;
    }
    return bits.join("")
  },
  inNoise(content, index) {
    let which = index % this.noises.length;
    let noise = this.noises[which].split("");
    let bits = content.split("");
    for ( let i=0; i < bits.length; i++ ) {
      let b = bits[i];
      let n = noise[i%noise.length];
      if ( n == "1" ) {
        bits[i] = b == "0" ? "1" : "0"
      }
    }
    return bits.join("")
  },
  inMirror(content, length) {
    let reg = new RegExp(".{1," + length + "}", "g");
    let chunks = content.match(reg);
    let processed = "";
    for ( let chunk of chunks ) {
      processed += this.inReverse(chunk);
    }
    return processed;
  }
}