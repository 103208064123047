'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    active_service: null,
    active_services: [],
    fo4mags: [],
    notes: null,
    gamenotes: null,
    debugging: false
  },
  mutations: {
    load (state, data) {
      // load an object into the state
      for (let p in data) {
        if (data.hasOwnProperty(p) && state.hasOwnProperty(p)) {
          if (p == "active_services") {
            state[p] = JSON.parse(data.active_services)
          } else if (p == "fo4mags") {
            state[p] = data.fo4mags.split(',')
          } else {
            state[p] = data[p];
          }
        }
      }
    },
    setActiveService (state, value) {
      state.active_service = value
      window.localStorage.setItem("active_service", value);
    },
    setNotes (state, value) {
      state.notes = value
      window.localStorage.setItem("notes", value);
    },
    setGameNotes (state, value) {
      state.gamenotes = value
      window.localStorage.setItem("gamenotes", value);
    },
    setFo4CollectedMags(state, value) {
      state.fo4mags = value
      window.localStorage.setItem("fo4mags", value);
    },
    activateService (state, value) {
      if ( state.active_services.indexOf(value) === -1 ) {
        state.active_services.push(value)
        window.localStorage.setItem("active_services", JSON.stringify(state.active_services));
      }
    },
    enableDebugging (state) {
      state.debugging = true;
    },
    disableDebugging (state) {
      state.debugging = false;
    }
  },
  actions: {},
  modules: {},
  getters: {
    activeService: state => {
      return state.active_service ? state.active_service : "home"
    },
    notes: state => {
      return state.notes ? state.notes : ""
    },
    gamenotes: state => {
      return state.gamenotes ? state.gamenotes : ""
    },
    activeServices: state => {
      return state.active_services ? state.active_services : []
    },
    fo4mags: state => {
      return state.fo4mags ? state.fo4mags : []
    },
    debugging: state => {
      return !!state.debugging
    },
  }
})
