import 'typeface-roboto/index.css';
import '@mdi/font/css/materialdesignicons.css'
import './css/custom.scss';

import Vue from 'vue'
import vuetify from './plugins/vuetify'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import messages from './i18n'

const i18n = new VueI18n({
  locale: 'en',
  messages,
})

// DayJs
import Dayjs from 'vue-dayjs';
Vue.use(Dayjs, {
  lang: 'en',
});

// custom Remote handler 
import remote from './plugins/remote'
Vue.prototype.$remote = remote;

import App from './App.vue'

Vue.config.productionTip = false

window.vue = new Vue({
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
