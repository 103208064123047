<template>
  <div v-if="isMounted" class="fo4-mags">
    <div v-for="(issues, mag) in mags" :key="'mag_'+mag" class="fo4-mag-magazine">
      <div class="d-flex align-center px-3 py-1 sticky primary-transparent">
        <span class="mr-2">{{ countCollected(mag) }} / {{ issues.length }}</span>
        <span>{{ mag }}</span>
        <v-spacer />
        <v-btn v-if="!isMagExpanded(mag)" icon @click="expandAll(mag)">
          <v-icon>mdi-arrow-expand-vertical</v-icon>
        </v-btn>
        <v-btn v-else icon @click="collapseAll(mag)">
          <v-icon>mdi-arrow-collapse-vertical</v-icon>
        </v-btn>
      </div>
      <div v-if="issues" class="fo4-mag-issues px-1">
        <v-row
          class="ma-0 fo4-mag-issue"
          dense
          v-for="(issue, index) in issues"
          :key="'mag_' + mag + '_issue_' + index"
          :class="{'expanded': isExpanded(mag, index)}"
        >
          <v-col cols="auto" class="d-flex align-center justify-middle text-center">
            <v-checkbox
              v-if="false && isExpanded(mag, index)"
              hide-details="auto"
              class="ma-0 mx-auto pa-0"
              :input-value="isCollected(mag, index)"
              @change="changed(mag, index, $event)"
            />
            <v-simple-checkbox
              v-else-if="isExpanded(mag, index)"
              class="ma-0 mx-auto pa-0"
              :value="isCollected(mag, index)"
              @click="changed(mag, index, !isCollected(mag, index))"
              color="primary"
            />
            <v-icon v-else-if="isCollected(mag, index)">mdi-check</v-icon>
            <v-icon v-else color="transparent">mdi-check</v-icon>
          </v-col>
          <v-divider vertical />
          <v-col cols="fill" class="pa-0">
            <div
              @click="toggle(mag, index)"
              :ripple="!isExpanded(mag, index)"
              class="pa-2 pl-3"
              :class="{'text-collected': !isExpanded(mag, index) && isCollected(mag, index)}"
            >{{ issue.name }}</div>
            <template v-if="isExpanded(mag, index)">
              <v-divider />
              <div class="pa-1">
                <div class="secondary pa-2 pb-0">{{ issue.location }}</div>
                <div class="secondary pa-2 pt-0 mb-1 text-content">{{ issue.description }}</div>
                <div class="primary-transparent-2 pa-2">{{ issue.effect }}</div>
              </div>
            </template>
          </v-col>
        </v-row>
      </div>
      <div class="fo4-mag-clear"></div>
    </div>
  </div>
</template>

<script>

import fo4mags from "@/config/fo4mags.json"

export default {
  name: "ServiceFo4mags",
  components: {},
  data: () => ({
    collected: [],
    expanded: [],
    isMounted: false,
  }),
  computed: {
    mags() {
      if (fo4mags) {
        return fo4mags;
      }
      return [];
    },
    collectedMags: {
      get() {
        return this.$store.getters.fo4mags;
      },
      set(value) {
        this.$store.commit('setFo4CollectedMags', value)
      }
    },
  },
  watch: {},
  props: [],
  mounted() {
    //console.log('test',mags)
    this.isMounted = true;
    this.collected = this.collectedMags;
  },
  created() {},
  destroyed() {},
  methods: {
    magToKey(mag) {
      let props = Object.getOwnPropertyNames(this.mags);
      return props.indexOf(mag);
    },
    keyToName(key) {
      let d = key.split('_');
      let mag = d[0];
      let issue = d[1];
      let props = Object.getOwnPropertyNames(this.mags);
      let magname = props[mag];
      let issuename = this.mags[magname][issue].name;
      return magname + "\n" + issuename;
    },
    isExpanded(mag, issue) {
      let key = this.magToKey(mag) +"_" + issue;
      if (this.expanded.indexOf(key) !== -1) {
        return true;
      }
      return false;
    },
    expand(mag, issue) {
      let key = this.magToKey(mag) +"_" + issue;
      if (this.expanded.indexOf(key) === -1) {
        this.expanded.push(key);
      }
    },
    collapse(mag, issue) {
      let key = this.magToKey(mag) +"_" + issue;
      let index = this.expanded.indexOf(key);
      if (index !== -1) {
        this.expanded.splice(index, 1);
      }
    },
    toggle(mag, issue) {
      if (this.isExpanded(mag, issue)) {
        this.collapse(mag, issue);
      } else {
        this.expand(mag, issue);
      }
    },
    isMagExpanded(mag) {
      let issues = this.mags[mag].length;
      for (let i=0;i<issues;i++) {
        if (this.isExpanded(mag, i)) {
          return true;
        }
      }
      return false;
    },
    expandAll(mag) {
      let issues = this.mags[mag].length;
      for(let i=0;i<issues;i++) {
        this.expand(mag, i)
      }
    },
    collapseAll(mag) {
      let issues = this.mags[mag].length;
      for(let i=0;i<issues;i++) {
        this.collapse(mag, i)
      }
    },
    isCollected(mag, issue) {
      let key = this.magToKey(mag) +"_" + issue;
      if (this.collected.indexOf(key) !== -1) {
        return true;
      }
      return false;
    },
    countCollected(mag) {
      let issues = this.mags[mag].length;
      let c = 0;
      for (let i=0;i<issues;i++) {
        if (this.isCollected(mag, i)){
          c++;
        }
      }
      return c;
    },
    changed(mag,issue,checked) {
      let key = this.magToKey(mag) +"_" + issue;
      if (checked) {
        return this.collect(key);
      } else {
        return this.remove(key);
      }
    },
    collect(key) {
      if (this.collected.indexOf(key) === -1) {
        let name = this.keyToName(key);
        if (confirm("collect magazine\n" + name) ) {
          this.collected.push(key);
          this.collectedMags = this.collected;
        } else {
          return false;
        }
      }
    },
    remove(key) {
      let index = this.collected.indexOf(key);
      if (index !== -1) {
        let name = this.keyToName(key);
        if (confirm("remove magazine\n" + name) ) {
          this.collected.splice(index, 1);
          this.collectedMags = this.collected;
        } else {
          return false;
        }
      }
    }
  },
};
</script>

<style lang="scss">
.fo4-mags {
  .v-input--selection-controls__input {
    margin:0;
  }
  .row.fo4-mag-issue:not(:last-child) {
    border-bottom: rgba(255,255,255,0.12) 1px solid;
  }
  .sticky {
    position: sticky;
    top: -1px;
    z-index: 2;
  }
  .fo4-mag-magazine {
    overflow: clip;
  }
  .fo4-mag-clear {
    height:1px;
  }
  .fo4-mag-issues {
    position: relative;
    overflow: hidden;
  }
  .text-collected {
    color: rgba(255,255,255,0.4);
  }
  .text-content {
    color: rgba(255,255,255,0.6);
  }
  .primary-transparent {
    background-color: rgba(33,150,243,0.7);
    backdrop-filter: blur(4px);
  }
  .primary-transparent-2 {
    background-color: rgba(33,150,243,0.3);
  }
}
</style>