<template>
  <v-app>
    <v-app-bar app v-if="toolbar"></v-app-bar>
    <v-main class="grey lighten-2">
      <div v-if="loaded" class="fill-height">
        <mobile-1 v-if="isRoute('mobile1')" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import store from "./store";
import Mobile1 from "./views/Mobile1";

export default {
  store,
  name: "App",
  components: {
    Mobile1
  },
  data: () => ({
    loaded: false,
    toolbar: false,
    location: null,
    isLoggedIn: true,
  }),
  methods: {
    isRoute(route) {
      return this.location.route == route;
    }
  },
  computed: {},
  mounted() {
    let myrouter = { route: "mobile1" };
    myrouter.goTo = (route) => {myrouter.route=route;};
    this.location = myrouter;

    this.loaded = true;
    store.commit('load', window.localStorage)
  },
  destroyed: function () {},
};
</script>

<style lang="scss">
.fill-height {
  height: 100%;
}
</style>