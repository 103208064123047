'use strict'

const strings = {
  app: {
    title: "PWA Test"
  },
  services: {
    home: {
      title: "PWA Test"
    },
    economy: {
      title: "Economy"
    },
    notes: {
      title: "Notes",
      types: {
        text: 'Text',
        password: 'Password'
      }
    },
    fo4mags: {
      title: "Fallout 4 Magazine collector"
    },
    gamenotes: {
      title: "Game Notes"
    }
  },
  views: {
    economy: {
      title: "Economy",
      bills: "Bills",
      expenses: "Expenses",
      incomes: "Incomes"
    }
  },
  messages: {
    general: {
      select: 'Select',
    },
    datetime: {
      longdateformat: 'dddd, MMMM Do, YYYY',
      month: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December'
      },
      day: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
      }
    }
  }
}

export default strings
