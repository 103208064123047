<template>
  <div>
    <v-card v-if="false" class="mt-0 ma-2">
      <v-calendar
        ref="calendar"
        v-model="currentDate"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        type="month"
      />
    </v-card>
    <v-card v-if="currentBudget">
      <v-row class="ma-0">
        <v-col class="text-no-wrap">{{ currentBudget.from }} - {{ currentBudget.to }}</v-col>
        <v-spacer />
        <v-col cols="auto">{{ currentBudget.amount }} kr</v-col>
      </v-row>
      <v-divider/>
      <v-row class="ma-0">
        <v-col class="text-center">
          <div class="subtitle-1">Today</div>
          <div><strong>{{ todaysBudget }}</strong> <span class="currency">kr</span></div>
        </v-col>
        <v-divider vertical />
        <v-col class="text-center">
          <div class="subtitle-1">Left</div>
          <div><strong>{{ todaysBudget - spentToday }}</strong> <span class="currency">kr</span></div>
        </v-col>
        <v-divider vertical />
        <v-col class="text-center">
          <div class="subtitle-1">Change</div>
          <div><span class="change success--text">+</span> <strong>5</strong> <span class="currency">kr</span></div>
        </v-col>
      </v-row>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn @click="openEditBudget">Edit</v-btn>
        <v-spacer />
        <v-btn color="red">Spend</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showEditBudgetDialog">
      <v-card>
        <v-card-title>Budget</v-card-title>
        <v-card-actions>
          <v-text-field hide-details="auto" label="From" v-model="editBudgetFrom" />
        </v-card-actions>
        <v-card-actions>
          <v-text-field hide-details="auto" label="To" v-model="editBudgetTo" />
        </v-card-actions>
        <v-card-actions>
          <v-text-field hide-details="auto" label="Budget" v-model="editBudgetAmount" />
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="showEditBudgetDialog=false">cancel</v-btn>
          <v-btn color="primary" @click="saveEditBudget">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "ServiceEconomy",
  components: {},
  data: () => ({
    currentDate: "2022-03-23",
    economyData: {
      budgets: [
        {
          from: "2022-03-25",
          to: "2022-04-24",
          amount: 9000
        }
      ],
      spent: [
        {
          date: "2022-02-24 12:03",
          amount: 2000
        },
        {
          date: "2022-02-25 12:03",
          amount: 200
        },
        {
          date: "2022-03-23 12:03",
          amount: 350
        },
        {
          date: "2022-03-24 12:03",
          amount: 200
        },
        {
          date: "2022-03-25 12:00",
          amount: 3000
        },
        { // crashes if if only 1 item .... fuck reduce, its shit
          date: "2022-03-26 13:00",
          amount: 100
        }
      ]
    },
    showEditBudgetDialog: false,
    editBudgetFrom: "",
    editBudgetTo: "",
    editBudgetAmount: 0
  }),
  computed: {
    spentByDate() {
      let spent = this.economyData.spent;
      let dates = {};
      for (let i in spent) {
        if (spent.hasOwnProperty(i)) {
          let date = this.$dayjs(spent.date).format("YYYY-MM-DD");
          if (! dates.hasOwnProperty(date)) {
            dates[date] = [];
          }
          dates[date].push(spent[i]);
        }
      }
      return dates;
    },
    spentMonths() {
      let dates = Object.keys(this.spentByDate);
      let months = [];
      for (let i in dates) {
        let month = this.$dayjs(dates[i]).format("YYYY-MM");
        if (months.indexOf(month) === -1) {
          months.push(month)
        }
      }
      return months;
    },
    currentBudgetIndex() {
      return 0;
      /*let budgets = this.economyData.budgets;
      for (let i in budgets) {
        if (budgets.hasOwnProperty(i)) {
          let from = this.$dayjs(budgets[i].from).startOf('day');
          let to = this.$dayjs(budgets[i].to).endOf('day');
          let now = this.$dayjs();
          if (this.between(now, from, to)) {
            return i;
          }
        }
      }
      return null;
      */
    },
    currentBudget: {
      get() {
        let index = this.currentBudgetIndex;
        if ( index || index === 0 || index === "0" ) {
          return this.economyData.budgets[index];
        }
        return null;
      },
      set(val) {
        let index = this.currentBudgetIndex;
        if ( index || index === 0 || index === "0" ) {
          this.economyData.budgets[index] = val;
        }
      }
    },
    spentCurrentBudget() {  
      let items = this.getSpentFromBudget( this.currentBudgetIndex, true );
      if (items) {
        return items.reduce((a, b) => {
          return {amount: a.amount + b.amount};
        }).amount
      }
      return 0;
    },
    daysLeft() {
      let budget = this.currentBudget;
      if (budget) {
        let date = this.$dayjs().startOf('day');
        let days = this.$dayjs(budget.to).diff(date, 'day');
        return days + 1;
      }
      return null;
    },
    todaysBudget() {
      let items = this.getSpentFromBudget( this.currentBudgetIndex, true );
      let amount = 0;
      if (items) {
        amount = items.reduce((a, b) => {
          return {amount: a.amount + b.amount};
        }).amount;
      }
      let left = this.currentBudget.amount - amount;
      return left / this.daysLeft;
    },
    spentToday() {
      let spent = this.economyData.spent;
      let items = spent.filter(s => {
        return this.$dayjs().isSame(s.date, 'day');
      });
      if (items) {
        return  items.reduce((a, b) => {
          return {amount: a.amount + b.amount};
        }).amount;
      }
      return 0;
    }
  },
  watch: {},
  props: [],
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    getSpentFromBudget(index, excludeToday) {
      let budget = this.economyData.budgets[index];
      let spent = this.economyData.spent;
      let from = this.$dayjs(budget.from).startOf('day');
      let to = this.$dayjs(budget.to).endOf('day');
      return spent.filter(s => {
        let date = this.$dayjs(s.date);
        if (excludeToday && this.$dayjs().isSame(date, 'day')) {
          return false;
        }
        return this.between(date, from, to)
      });
    },
    between(date, from, to) {
      return (date.isSame(from) || date.isAfter(from)) && (date.isSame(to) || date.isBefore(to));
    },
    validateDate(date) {
      if (typeof date === "string") {
        return !!date.match(/^\d{4}-\d{2}-\d{2}$/);
      }
      return false;
    },
    setBudgetFromDate(date) {
      if (this.validateDate(date)) {
        this.currentBudget.from = date;
        return true;
      }
      return false;
    },
    setBudgetToDate(date) {
      if (this.validateDate(date)) {
        this.currentBudget.to = date;
        return true;
      }
      return false;
    },
    openEditBudget() {
      this.editBudgetFrom = this.currentBudget?.from || "";
      this.editBudgetTo = this.currentBudget?.to || "";
      this.editBudgetAmount = this.currentBudget?.amount || "";
      this.showEditBudgetDialog = true;
    },
    saveEditBudget() {
      this.setBudgetFromDate(this.editBudgetFrom);
      this.setBudgetToDate(this.editBudgetTo);
      this.currentBudget.amount = this.editBudgetAmount;
      this.editBudgetFrom = "";
      this.editBudgetTo = "";
      this.showEditBudgetDialog=false;
    }
  },
};
</script>

<style lang="scss" scoped>
.currency {
  font-size: 0.8rem;
  opacity: 0.6;
}
</style>
