<template>
  <div>
    <v-card v-if="batteryData.status !== 'disabled'" dark class="ma-2">
      <v-card-actions><v-spacer /><strong class="mr-2">{{batteryPercent}}</strong><v-icon>{{batteryIcon}}</v-icon></v-card-actions>
      <v-divider v-if="debugging" />
      <v-card-text v-if="debugging"><pre>{{ batteryData }}</pre></v-card-text>
    </v-card>

    <v-card dark class="mt-0 ma-2">
      <v-card-title>Hello world!</v-card-title>
      <v-card-text>This is a test to build semi-native android apps</v-card-text>
    </v-card>

    <v-card dark class="ma-2">
      <v-card-text>You can active services you know about below.</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-text-field class="ma-0 pa-0 pb-1 mr-2" hide-details="auto" v-model="activateServiceName" placeholder="service to activate" :error-messages="serviceError" />
        <v-btn color="primary" :disabled="activateServiceName.length < 3" @click="activateService">activate</v-btn>
      </v-card-actions>
    </v-card>

    <v-card dark class="ma-2" v-if="batteryData.status == 'disabled'">
      <v-card-actions>
        <v-spacer /><v-btn color="primary" @click="testBattery">Test battery api</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "ServiceHome",
  components: {},
  data: () => ({
    activateServiceName: "",
    serviceError: null,
    batteryData: {
      'status': 'disabled',
      'charging': null,
      'level': 0
    },
  }),
  computed: {
    textmessage: {
      get() {
        let text = window.localStorage.getItem("textmessage");
        return typeof text == "string" && text ? text : "";
      },
      set(val) {
        window.localStorage.setItem("textmessage", val);
      }
    },
    debugging: {
      get() {
        return this.$store.getters.debugging;
      }
    },
    batteryPercent() {
      if (this.batteryData.status !== 'loaded') {
        return "unknown";
      }
      return Math.ceil(this.batteryData.level * 100) + "%";
    },
    batteryIcon() {
      let icon = "mdi-battery";
      if (this.batteryData.status !== 'loaded') {
        icon += "-unknown";
      } else {
        if (this.batteryData.charging) {
          icon += "-charging";
        }
        if (this.batteryData.level == 1 ) {icon += this.batteryData.charging ? "-100" : ""}
        else if (this.batteryData.level >= 0.9 ) {icon += "-90"}
        else if (this.batteryData.level >= 0.8 ) {icon += "-80"}
        else if (this.batteryData.level >= 0.7 ) {icon += "-70"}
        else if (this.batteryData.level >= 0.6 ) {icon += "-60"}
        else if (this.batteryData.level >= 0.5 ) {icon += "-50"}
        else if (this.batteryData.level >= 0.4 ) {icon += "-40"}
        else if (this.batteryData.level >= 0.3 ) {icon += "-30"}
        else if (this.batteryData.level >= 0.2 ) {icon += "-20"}
        else if (this.batteryData.level >= 0.1 ) {icon += "-10"}
        else {icon += "-outline"}
      }
      return icon;
    }
  },
  watch: {},
  props: [],
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    activateService () {
      this.serviceError = null;
      if (this.activateServiceName === "economy") {
        this.$store.commit('activateService', "economy")
      } else if (this.activateServiceName === "notes") {
        this.$store.commit('activateService', "notes")
      } else if (this.activateServiceName === "fo4mags") {
        this.$store.commit('activateService', "fo4mags")
      } else if (this.activateServiceName === "gamenotes") {
        this.$store.commit('activateService', "gamenotes")
      } else {
        this.serviceError = "No such service exists"
      }
    },
    testBattery() {
      try {
        this.batteryData.status = 'initializing';
        window.navigator.getBattery().then((battery) => {
          this.batteryData.charging = !!battery.charging;
          this.batteryData.level = battery.level;

          battery.addEventListener("chargingchange", () => {
            this.batteryData.charging = !!battery.charging;
          });
          battery.addEventListener("levelchange", () => {
            this.batteryData.level = battery.level;
          });
          this.batteryData.status = 'loaded';
        }).catch((e) => {
          console.log('error', e)
        });
      } catch(e) {
        this.batteryData.status = 'error';
        console.error(e);
      }
    }
  },
};
</script>
