<template>
  <div class="flex-grow-1 overflow-auto" :class="{'d-flex flex-column': isEditing}">
    <v-card v-if="!isEditing" class="mt-0 ma-2">
      <v-card-actions>
        <v-select
            class="ma-0 mx-auto pa-0 game-select"
            v-model="currentGame"
            :items="games"
            item-text="name"
            item-value="value"
            hide-details="auto"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-if="!isEditing" class="mt-0 ma-2">
      <v-card-actions>
        <v-select
          class="ma-0 mx-auto pa-0 game-select"
          v-model="filterMainLocation"
          :items="mainLocationsFilters"
          hide-details="auto"
        />
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-if="notes.length == 0" class="mt-0 ma-2">
      <v-card-text>
        <div class="text-center">No notes</div>
      </v-card-text>
    </v-card>

    <game-note
      v-else
      v-for="(note, i) in notes"
      :key="notes.length + '_' + i"
      :class="{'mt-5': i>0 && !isEditing, 'mx-2': !isEditing, 'flex-grow-1 flex-shrink-0': isEditing}"
      v-model="notes[i]"
      :editing="editingNote==i"
      :available-labels="labels"
      :available-tags="tags"
      :available-main-locations="mainLocations"
      :available-sub-locations="subLocations"
      :is-hidden="noteFilter(note) || (note.checked && !showChecked && editingNote!=i) || (isEditing && editingNote!=i)"

      @edit="editNote(i)"
      @delete="deleteNote(i)"
      @close="editNote(null)"
    />

    <v-card v-if="!isEditing" class="ma-2" color="transparent">
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="newNote">new note</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-if="!isEditing" class="ma-2" color="transparent">
      <v-card-actions>
        <v-spacer />
        <v-checkbox
            label="Show Checked"
            class="ma-0 mx-auto pa-0"
            v-model="showChecked"
            hide-details="auto"
        />
      </v-card-actions>
    </v-card>

    <div v-if="debugging">
      <v-card class="ma-2">
        <v-card-text>{{ storedNotes }}</v-card-text>
      </v-card>
      <v-card class="ma-2">
        <v-card-actions>
          <v-btn color="primary" @click="migrateNotes">migrate</v-btn>
        </v-card-actions>
      </v-card>
    </div>

  </div>
</template>

<script>

import GameNote from '@/components/GameNote'

export default {
  name: "ServiceGameNotes",
  components: {
    GameNote
  },
  data: () => ({
    notes: [],
    editingNote: null,
    currentGame: null,
    showChecked: false,
    games: [],
    filterMainLocation: null
  }),
  computed: {
    isEditing() {
      return this.editingNote !== null;
    },
    storedNotes: {
      get() {
        return this.$store.getters.gamenotes;
      },
      set(value) {
        this.$store.commit('setGameNotes', value)
      }
    },
    debugging: {
      get() {
        return this.$store.getters.debugging;
      }
    },
    labels: {
      get() {
        let labels = [];
        for(let note of this.notes) {
          let nl = note && typeof note == "object" && note.hasOwnProperty('label') ? note.label : null;
          if (typeof nl == "string" && nl.length > 0) {
            labels.push(nl.trim());
          }
        }
        return labels;
      }
    },
    tags: {
      get() {
        let tags = [];
        for(let note of this.notes) {
          let nt = note && typeof note == "object" && note.hasOwnProperty('tags') ? note.tags : null;
          if (typeof nt == "string" && nt.length > 0) {
            let nta = nt.split(',');
            for(let ntat of nta) {
              tags.push(ntat.trim());
            }
          }
        }
        // todo: unique
        return tags;
      }
    },
    mainLocations: {
      get() {
        let locations = [];
        for(let note of this.notes) {
          let nl = note && typeof note == "object" && note.hasOwnProperty('main_location') ? note.main_location : null;
          if (typeof nl == "string" && nl.length > 0) {
            locations.push(nl.trim());
          }
        }
        return locations;
      }
    },
    mainLocationsFilters() {
      let filters = [];
      filters.push({text: 'No location filter', value: null})
      for(let p of this.mainLocations) {
        filters.push({text: p, value: p});
      }
      return filters;
    },
    subLocations: {
      get() {
        let locations = [];
        for(let note of this.notes) {
          let nl = note && typeof note == "object" && note.hasOwnProperty('sub_location') ? note.sub_location : null;
          if (typeof nl == "string" && nl.length > 0) {
            locations.push(nl.trim());
          }
        }
        return locations;
      }
    }
  },
  watch: {
    notes: {
      deep:true,
      handler(val) {
        this.save();
      }
    }
  },
  props: [],
  mounted() {
    this.load();
  },
  created() {},
  destroyed() {},
  methods: {
    load() {
      let data = this.storedNotes;
      let parsed = typeof data === "string" && data ? JSON.parse(data) : [];
      this.notes = parsed;

      let games = [];
      games.push({name: 'none', value: null});
      games.push({name: 'Metroid Prime', value: 'metroid-prime'}); // TODO: dont do this, make it so we can ACTUALLY add games
      for(let note of this.notes) {
        let ng = note && typeof note == "object" && note.hasOwnProperty('game') ? note.game : null;
        if (typeof ng == "string" && ng.length > 0) {
          games.push({name: ng.trim(), value: ng.trim()});
        }
      }
      this.games = games;

      if (games.length > 1) {
        this.currentGame = this.games[1].value;
      }
    },
    save() {
      this.storedNotes = JSON.stringify(this.notes);
    },
    newNote() {
      this.notes.push({
        'game': this.currentGame,
        'text': '',
        'label': '',
        'main_location': '',
        'sub_location': '',
        'tags': '',
        'checked': false
      })
      this.editNote(this.notes.length-1);
    },
    editNote(index) {
      this.editingNote=index;
    },
    deleteNote(index) {
      if ( ! confirm('Delete note?') ) {
        return;
      }
      this.editingNote=null;
      this.notes.splice(index, 1);
    },
    migrateNotes() {
      for(let key in this.notes) {
        console.log('checking', key, this.notes[key]);
        if ( !this.notes[key].hasOwnProperty('sub_location') && this.notes[key].hasOwnProperty('location') && this.notes[key].location) {
          this.notes[key].sub_location = this.notes[key].location;
          delete this.notes[key].location;
        } else {
          console.log('no migration needed for', key)
        }
      }
      this.save();
    },
    noteFilter(note) {
      if( this.filterMainLocation && note.main_location !== this.filterMainLocation ) {
        return true;
      }
      return false;
    }
  },
};
</script>


<style lang="scss" scoped>
.game-select {}
</style>