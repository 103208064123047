<template>
  <div class="fill-height black d-flex flex-column">
    <v-app-bar color="black" class="text-center flex-shrink-1 flex-grow-0">
      <v-app-bar-nav-icon @click="service_nav = !service_nav" />
      <v-spacer />
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer />
      <v-menu left bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="toggleDebugging">
            <v-list-item-title>{{ debugging ? "Disable Debugging" : "Enable Debugging" }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="service_nav"
      absolute
      temporary
    >
      <v-subheader>Services</v-subheader>
      <v-divider />
      <v-list nav dense>
        <v-list-item-group v-model="activeService">
          <v-list-item v-for="service_option in activeServices" :key="service_option.id" :value="service_option.id">
            <v-list-item-icon class="mr-3"><v-icon>{{ service_option.icon }}</v-icon></v-list-item-icon>
            <v-list-item-title>{{ $t('services.' + service_option.id + ".title" ) }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <component v-bind:is="service.component" />
  </div>
</template>

<script>

import ServiceHome from "@/services/ServiceHome"
import ServiceEconomy from "@/services/ServiceEconomy"
import ServiceNotes from "@/services/ServiceNotes"
import ServiceFo4mags from "@/services/ServiceFo4mags"
import ServiceGameNotes from "@/services/ServiceGameNotes"

export default {
  name: "Mobile1",
  components: {
    ServiceHome,
    ServiceEconomy,
    ServiceNotes,
    ServiceFo4mags,
    ServiceGameNotes
  },
  data: () => ({
    service_nav: false,
    services: {
      'home': {
        id: 'home',
        icon: 'mdi-home',
        component: 'service-home'
      },
      'economy': {
        id: 'economy',
        icon: 'mdi-currency-usd',
        component: 'service-home'//'service-economy'
      },
      'notes': {
        id: 'notes',
        icon: 'mdi-text-box-outline',
        component: 'service-notes'
      },
      'fo4mags': {
        id: 'fo4mags',
        icon: 'mdi-text-box-outline',
        component: 'service-fo4mags'
      },
      'gamenotes': {
        id: 'gamenotes',
        icon: 'mdi-text-box-outline',
        component: 'service-game-notes'
      }
    }
  }),
  computed: {
    debugging: {
      get() {
        return this.$store.getters.debugging;
      },
      set(val) {
        if (val) {
          this.$store.commit('enableDebugging')
        } else {
          this.$store.commit('disableDebugging')
        }
      }
    },
    activeService: {
      get() {
        return this.$store.getters.activeService;
      },
      set(value) {
        this.$store.commit('setActiveService', value)
      }
    },
    service() {
      let service = this.activeService;
      if ( service ) {
        return this.services[service];
      }
      return this.services.home;
    },
    title() {
      let service = this.activeService;
      if (service && service !== "home") {
        return this.$t('services.' + service + '.title')
      }
      return this.$t('app.title');
    },
    activeServices() {
      let active = this.$store.getters.activeServices;
      if ( ! active ) {
        active = [];
      }
      let services = {};
      for (let i in this.services) {
        if (this.services.hasOwnProperty(i) && active.indexOf(i) !== -1 || i == 'home') {
          services[i] = this.services[i];
        }
      }
      return services
    }
  },
  watch: {
    activeService() {
      this.service_nav = false;
    }
  },
  props: [],
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    toggleDebugging() {
      this.debugging = !this.debugging;
    }
  },
};
</script>
