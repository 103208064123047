<template>
  <div>
    <v-card v-if="notes.length == 0" class="mt-0 ma-2">
      <v-card-text>
        <div class="text-center">No notes</div>
      </v-card-text>
    </v-card>
    <note
      v-else
      v-for="(note, i) in notes"
      :key="notes.length + '_' + i"
      class="mx-2"
      :class="{'mt-2': i>0}"
      v-model="notes[i]"
      :type-options="typeOptions"
      :editing="editingNote==i"

      @edit="editNote(i)"
      @delete="deleteNote(i)"
      @close="editNote(null)"
    />

    <v-card class="ma-2" color="transparent">
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="newNote('text')">new note</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-if="false" class="ma-2">
      <v-card-text>{{ notes }}</v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="save">save</v-btn>
        <v-btn color="primary" @click="load">load</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="debugging" class="ma-2">
      <v-card-text>{{ storedNotes }}</v-card-text>
    </v-card>
  </div>
</template>

<script>

import Note from '@/components/Note'

export default {
  name: "ServiceNotes",
  components: {
    Note
  },
  data: () => ({
    notes: [],
    types: [
      'text',
      'password'
    ],
    editingNote: null,
  }),
  computed: {
    storedNotes: {
      get() {
        return this.$store.getters.notes;
      },
      set(value) {
        this.$store.commit('setNotes', value)
      }
    },
    debugging: {
      get() {
        return this.$store.getters.debugging;
      }
    },
    typeOptions() {
      let opts = [];
      for (let t of this.types) {
        opts.push({
          'text': this.$t('services.notes.types.' + t),
          'value': t
        });
      }
      return opts;
    }
  },
  watch: {
    notes: {
      deep:true,
      handler(val) {
        this.save();
      }
    }
  },
  props: [],
  mounted() {
    this.load();
  },
  created() {},
  destroyed() {},
  methods: {
    load() {
      let data = this.storedNotes;
      let parsed = typeof data === "string" && data ? JSON.parse(data) : [];
      this.notes = parsed;
    },
    save() {
      this.storedNotes = JSON.stringify(this.notes);
    },
    newNote(type) {
      this.notes.push({
        'title': '',
        'data': '',
        'type': type
      })
      this.editNote(this.notes.length-1);
    },
    editNote(index) {
      console.log('editNote', index);
      this.editingNote=index;
    },
    deleteNote(index) {
      this.notes.splice(index, 1);
      /*
      let notes = [];
      for (let i in this.notes) {
        if ( i != index ) {
          notes.push(this.notes[i]);
        }
      }
      this.notes = notes;
      */
    }
  },
};
</script>
